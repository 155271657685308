import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { Observable } from 'rxjs';

import {AuthService} from './auth.service';
import {DataService} from './data.service';
import {map,tap,catchError} from  'rxjs/operators'




@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private dataService:DataService,
    private router: Router,

  ) {}



  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):



    Observable<boolean> | Promise<boolean> | boolean {
      var res = this.auth.isLoggedIn();

     console.log("Autorotia");
     console.log(res);
        if (!res) {

          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});

        return false;
      }


      return true;

      if (this.verifica())
      {

        return true;
      }
      else
      {

        this.router.navigate(['/cart/login'], { queryParams: { returnUrl: state.url }});

        return false;

      }
  }


verifica(): Observable<boolean> {

return this.dataService.getCartAut()
     .pipe(map((res) => {

          return true;
      },
      error => {



          if (error == 403 || error == 401) {
              return false;
          }
          return false;
      }));

     // return false;


}


}
